.page-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Add transparency for a semi-opaque background */
    z-index: 9999; /* Set the z-index to ensure the loader is displayed on top of other elements */
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.2s, opacity 0.2s;
  }
  
  .visible {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }
  
  .hidden {
    visibility: hidden;
    opacity: 0;
  }
  
  .loader {
    border: 6px solid #fff;
    border-top: 6px solid #20958c; /* Set the color of the loader, e.g., #007bff for blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }